.header{
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    
    img{
        height: 70px;
        margin-bottom: 20px;
    }
}
