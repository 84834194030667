.bookingContainer {
  padding-top: 3%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('../images/bg3.jpg');
    background-size: cover;
    background-position: center;
  }
  
  .bookingForm {
    background: rgba(255, 255, 255, 0.1); 
    border-radius: 20px;
    padding: 1rem;
    width: 70%; 
    max-width: 400px; 
    backdrop-filter: blur(10px); 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); 
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .reservationIframe {
    border: none;
    width: 65%;
    height: 78vh;
    align-items: center;
    justify-content: center;
  }

  .form-container {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 750px){
    .bookingForm {
       padding: 20px;
      }
      
    .reservationIframe {
        width: 100%;
        height: 65vh;
       
      }
    
  }

